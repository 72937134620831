<template>
  <div class="row pt-8">
    <Button
      v-for="(type, i) in modTypes"
      :class="type.toLowerCase() + '-background'"
      :key="i"
      :label="type"
      @click="emitType(type)"
    />
  </div>
</template>

<script>
import Button from "primevue/button";
export default {
  components: {
    Button,
  },
  setup(props, { emit }) {
    const modTypes = ["Sales", "Logistics", "HR", "Others"];
    const emitType = (type) => {
      emit("type-selected", type);
    };
    return {
      modTypes,
      emitType,
    };
  },
};
</script>
<style scoped>
.row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
}
button {
  width: calc(100% / 4);
  color: #1f2937;
  margin-right: 1rem;
  padding: 1rem 2rem;
}
button:hover {
  color: #fcd25e;
  background: transparent;
}
button:last-child {
  margin: 0;
}
</style>
