<template>
  <Menubar class="custom:ml-72 xl:ml-96">
    <template #start>
      <div class="h-full flex items-center">
        <h1 class="route-title text-2xl text-left">{{ currentRouteName }}</h1>
      </div>
      <Divider />
    </template>
  </Menubar>
</template>

<script setup>
import { ref, watch } from "vue";
import Menubar from "primevue/menubar";
import { useRoute } from "vue-router";
import Divider from "primevue/divider";

const route = useRoute();
const currentRouteName = ref(route.meta.routeName);

watch(
  () => route.path,
  () => {
    currentRouteName.value = route.meta.routeName;
  }
);
</script>

<style scoped>
.p-menubar {
  background-color: transparent !important;
  padding-left: 0;
  padding-right: 0;
  max-height: 60px;
}
</style>
