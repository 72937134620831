<template>
  <div class="container flex flex-col justify-between custom:ml-72 xl:ml-96">
    <div class="row placeholder"></div>
    <div class="row">
      <ModulesTableComponent :data="data" />
    </div>
  </div>
</template>

<script setup>
import ModulesTableComponent from "./table/ModulesTableComponent.vue";
import { ref, onMounted, defineProps } from "vue";

const props = defineProps(["data"]);
const moduleTypes = ref({
  sales: 0,
  logistics: 0,
  hr: 0,
  others: 0,
});

onMounted(() => {
  countModuleTypes();
});

const countModuleTypes = () => {
  props.data.forEach((item) => {
    if (moduleTypes.value[item.department.toLowerCase()] !== undefined) {
      moduleTypes.value[item.department.toLowerCase()]++;
    }
  });
};
</script>

<style scoped>
.placeholder {
  height: 200px;
  border: 1px solid rgba(196, 233, 255, 0.3);
}
.row {
  width: 100%;
}
.card-container {
  color: rgb(255, 255, 255);
  width: calc(100% / 4);
}
.p-card {
  background-color: transparent !important;
}

@media (max-width: 1256px) {
  .placeholder{
    height: 100px;
  }
}
</style>
