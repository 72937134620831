<template>
  <DataView :value="modules" :rows="4" :paginator="true" class="relative">
    <template #list="slotProps">
      <span class="absolute bottom-4 left-0 custom:text-xs xl:text-base">{{
        dictionary.dataview_paginator1 +
        modules.length +
        dictionary.dataview_paginator2
      }}</span>
      <div v-if="!store.filterActive" class="flex justify-between py-4">
        <h1 class="text-xl text-left m-0">{{ dictionary.dataview_title }}</h1>
        <i class="pi pi-list"></i>
      </div>
      <div class="flex flex-row flex-wrap">
        <div
          v-for="(item, index) in slotProps.items"
          :key="index"
          class="w-1/2 pr-6"
        >
          <div class="flex flex-row">
            <div class="w-1/2">
              <img
                :src="
                  require(`../../../assets/mod/${item.short.toLowerCase()}.jpg`)
                "
                :alt="item.short"
                class="w-full border-round custom:mt-6 xl:mt-0"
              />
            </div>
            <div class="flex flex-row justify-between items-center w-1/2 pl-6">
              <div
                class="flex flex-col justify-between items-start xl:gap-2 custom:gap-0"
              >
                <div>
                  <span
                    :class="
                      item.department.toLowerCase() +
                      '-bg p-1 rounded-lg mr-4 custom:text-xs xl:text-base'
                    "
                  >
                    {{ item.department }}
                  </span>
                  <span
                    class="text-surface-900 font-medium text-sm custom:text-xs xl:text-base"
                    >{{ dictionary.dataview_item2 + item.duration }} min</span
                  >
                </div>
                <span
                  :class="
                    item.department.toLowerCase() +
                    '-txt custom:text-base xl:text-xl'
                  "
                  >{{ item.short }}</span
                >
                <div>
                  <span class="custom:text-xs xl:text-sm">{{
                    dictionary.dataview_item3
                  }}</span>
                </div>
                <span class="text-left custom:text-xs xl:text-sm">{{
                  item.specification[0].objective
                }}</span>
                <div class="flex flex-row w-full">
                  <router-link
                    :to="{ name: 'module' }"
                    @click="handleDisplayModule(item)"
                    class="dataview-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 custom:mr-3 xl:p-2 xl:mr-4 xl:text-base xl:h-auto xl:mt-0 rounded"
                  >
                    <i class="pi pi-map"></i>
                    {{ dictionary.dataview_show }}
                  </router-link>

                  <router-link
                    :to="{ name: 'module' }"
                    @click="handleDisplayModule(item)"
                    class="dataview-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 xl:p-2 xl:text-base xl:h-auto xl:mt-0 rounded"
                    :class="{ disabled: true }"
                  >
                    <i class="pi pi-arrow-right-arrow-left"></i>
                    {{ dictionary.dataview_compare }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <Divider />
        </div>
      </div>
    </template>
  </DataView>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import { useStore } from "../../../../store/store.js";
import DataView from "primevue/dataview";
import Divider from "primevue/divider";
import { dictionary } from "@/dictionary";
const props = defineProps({
  data: Array,
});
const modules = ref(props.data);

const store = useStore();
watch(
  () => props.data,
  (newData) => {
    modules.value = newData;
  },
  { immediate: true }
);

const handleDisplayModule = (mod) => {
  store.setSelectedModule(mod);
};
</script>
<style scoped>
.disabled{
  pointer-events: none;
  color: grey;
  background-color: #1F2937;
}
</style>
