export const dictionary = {
  login: "Access",
  login_password: "Password",
  login_username: "Username",
  login_btn: "Submit",
  login_access: "Access granted.",
  menu_search: "Search (inactive)",
  sidebar_title: "mapper",
  sidebar_item1: "Dashboard",
  sidebar_item2: "Module library (inactive)",
  sidebar_item3: "Filter modules",
  sidebar_item4: "Map modules",
  sidebar_item5: "Module comparator",
  sidebar_item6: "Order training (inactive)",
  sidebar_item7: "Competence Profile (inactive)",
  sidebar_copy: "Copyright © E-Learning Department 2024",
  dashboard_overview: "Modules overview:",
  dashboard_mod_type: "Module type: ",
  dashboard_mod_nr: "Number of modules: ",
  dataview_title: "Our recent modules:",
  dataview_item1: "Department: ",
  dataview_item2: "Duration: ",
  dataview_item3: "Objective: ",
  dataview_show: "Show Map",
  dataview_compare: "Compare",
  dataview_paginator1: "Select page to show more of our ",
  dataview_paginator2: " recent Modules",
  filter_label1: "By Module Type",
  filter_label2: "By Department",
  filter_label3: "By Topics",
  filter_label4: "By Module Short Name",
  filter_label5: "By Target Group",
  filter_label6: "By Key Words",
  filter_label7: "Sales",
  filter_label8: "Logistics",
  filter_label9: "HR",
  filter_label10: "Others",
  filter_btn_search: "Filter",
  filter_btn_clear: "Clear",
  filter_mod_found: "Modules found:",
  filter_dialog_title: "Title: ",
  filter_dialog_short: "Short: ",
  filter_dialog_duration: "Duration (min): ",
  filter_dialog_type: "Module type: ",
  filter_dialog_element: "Training elements: ",
  filter_dialog_department: "Department: ",
  filter_dialog_target: "Target: ",
  filter_dialog_employee: "Employee: ",
  filter_dialog_specification: "Training specification: ",
  filter_dialog_objective: "Objective: ",
  filter_dialog_topic: "Topic: ",
  filter_dialog_stopic: "Sub topic",
  filter_dialog_skill_lvl: "Skill level",
  filter_dialog_knowledge: "Knowledge",
  filter_dialog_knowledge_description: "Knowledge description",
  map_modules_no_trainings: "No categories to display. Select a training type to view available modules.",
  detailed_map_tags: "KEY WORDS",
  detailed_map_topics: "TOPICS",
  detailed_map_subtopics: "SUBTOPICS",
  detailed_map_info: "BASIC INFO",
  detailed_map_duration: "Duration (min): ",
  detailed_map_employees: " employees",
  comparator_btn_compare: "Compare",
  comparator_btn_clear: "Clear trainings",
  comparator_dropdown_label: "Select a training to compare",
  comparator_common: "Common elements",
  comparator_differences: "Differences",
  library_filters: "Selected filters:",
};