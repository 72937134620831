<template>
  <div class="container">
    <Dialog
      :header="dictionary.login"
      :closable="false"
      :visible="true"
      class="login-dialog"
    >
      <div v-if="loading">
        <ProgressSpinner
          class="spinner flex items-center justify-center"
          strokeWidth="2"
          animationDuration="3s"
        />
      </div>
      <div v-else class="form-wrapper">
        <form @submit.prevent="onSubmit">
          <FloatLabel>
            <InputText id="username" v-model="username" />
            <label for="username">{{ dictionary.login_username }}</label>
          </FloatLabel>
          <FloatLabel>
            <Password
              v-model="password"
              id="password"
              :feedback="false"
              toggleMask
            />
            <label for="password">{{ dictionary.login_password }}</label>
            <small className="p-error">{{ errmsg }}</small>
          </FloatLabel>
          <Button
            type="submit"
            class="submit-btn"
            :label="dictionary.login_btn"
          />
        </form>
      </div>
    </Dialog>
    <Toast ref="toast" />
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Toast from "primevue/toast";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import { dictionary } from "../../dictionary.js";
import { ref } from "vue";
import { useStore } from "../../../store/store.js";
import { useRouter } from "vue-router";

export default {
  components: {
    Dialog,
    Toast,
    Button,
    ProgressSpinner,
    Password,
    InputText,
    FloatLabel,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = ref(null);
    const loading = ref(false);
    const password = ref("");
    const username = ref("eld_user");
    const errmsg = ref("");

    const onSubmit = async (event) => {
      event.preventDefault();
      loading.value = true;
      try {
        const response = await fetch("https://eld-mapper-backend.api.prod.sit.sys.odj.cloud/auth", {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username.value,
            password: password.value,
          }),
        });

        if (response.ok) {
          setToast();
          errmsg.value = "";
          const timer = setTimeout(() => {
            loading.value = false;
            store.fetchData();
            router.push({ name: "dashboard" });
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          const message = await response.text();
          errmsg.value = message;
          console.error(message);
          loading.value = false;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const setToast = () => {
      toast.value.add({
        severity: "success",
        summary: "Success",
        detail: dictionary.login_access,
        life: 2000,
      });
    };

    return {
      dictionary,
      toast,
      loading,
      password,
      username,
      store,
      errmsg,
      setToast,
      onSubmit,
    };
  },
};
</script>

<style scoped>
.submit-btn {
  background-color: #fbbf24;
  color: #1f2937;
  width: 33.333%;
  transition: ease-in;
  transition-duration: 0.3s;
}
.submit-btn:hover {
  background-color: #1f2937;
  color: #fbbf24;
  width: 33.333%;
}
.spinner {
  width: 50px;
  height: 50px;
}
</style>