<template>
  <div class="container relative">
    <ModuleTypes @type-selected="filterModules" />
    <div v-if="showCategory" class="grid-container">
      <router-link
        v-for="(mod, index) in filteredModules"
        :key="index"
        :to="{ name: 'module' }"
        @click="handleDisplayModule(mod)"
        class="grid-item"
        :class="mod.department.toLowerCase() + '-background'"
      >
        {{ mod.short }}
      </router-link>
    </div>
    <div v-else class="info">
      <p class="text-xl">{{ dictionary.map_modules_no_trainings }}</p>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "../../../store/store.js";
import ModuleTypes from "./ModuleTypes.vue";
import { dictionary } from "../../dictionary.js";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    ModuleTypes,
  },
  setup(props) {
    const store = useStore();
    const modules = ref(props.data);
    const selectedType = ref(null);
    const showCategory = ref(false);

    const filteredModules = computed(() => {
      return modules.value
        .filter((mod) => mod.department === selectedType.value)
        .sort((a, b) => a.short.localeCompare(b.short));
    });

    const filterModules = (type) => {
      selectedType.value = type;
      showCategory.value = !!type;
    };

    const handleDisplayModule = (mod) => {
      store.setSelectedModule(mod);
    };

    return {
      showCategory,
      filteredModules,
      dictionary,
      filterModules,
      handleDisplayModule,
    };
  },
};
</script>

<style scoped>
.info{
  width: 100%;
  height: 33.333vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 3rem;
}
.grid-item {
  transition: color 0.3s ease;
  padding: 2rem 3rem;
  -webkit-box-shadow: 14px 14px 20px -15px rgba(31, 41, 55, 1);
  -moz-box-shadow: 14px 14px 20px -15px rgba(31, 41, 55, 1);
  box-shadow: 14px 14px 20px -15px rgba(31, 41, 55, 1);
}
.grid-item:hover {
  color: #fcd25e;
  -webkit-box-shadow: 0px 0px 24px 0px rgba(251, 198, 52, 1);
  -moz-box-shadow: 0px 0px 24px 0px rgba(251, 198, 52, 1);
  box-shadow: 0px 0px 24px 0px rgba(251, 198, 52, 1);
}
</style>
