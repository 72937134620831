<template>
  <div v-if="data.length" class="content">
    <ContentDisplay :data="data" />
  </div>
  <div v-else class="spinner card flex justify-center">
    <LoginComponent />
  </div>
  <img class="mapbg" src="./assets/mapbcg.png" alt="background" />
</template>

<script>
import { useStore } from "../store/store.js";
import ContentDisplay from "./components/ContentDisplay.vue";
import LoginComponent from "./components/login/LoginComponent.vue";
import "../style/font.css";
import { ref, watchEffect } from "vue";

export default {
  name: "App",
  components: {
    LoginComponent,
    ContentDisplay,
  },
  setup() {
    const store = useStore();
    const data = ref(JSON.parse(sessionStorage.getItem("data")) || []);
    watchEffect(() => {
      if (!data.value.length) {
        data.value = store.data;
      }
    });

    return { data };
  },
};
</script>

<style>
#app {
  font-family: lidlFontProReg, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  position: relative;
  color: #c4e9ff !important;
  width: 100%;
  height: 100%;
  margin: 0;
}
.mapbg {
  width: 100%;
  height: 100%;
  opacity: 0.1;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}
</style>
