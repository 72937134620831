<template>
  <div class="container">
    <div v-if="showDropdown" class="pt-4 w-full flex flex-row">
      <div class="col w-1/2 mr-4">
        <div class="card flex justify-content-center mt-4">
          <FloatLabel class="w-full">
            <Dropdown
              v-model="selectedTraining1"
              inputId="dd-tr1"
              :options="modules"
              optionLabel="short"
              class="w-full"
              filter
            />
            <label for="dd-tr1">{{
              dictionary.comparator_dropdown_label
            }}</label>
          </FloatLabel>
        </div>
      </div>
      <div class="col w-1/2 mr-4">
        <div class="card flex justify-content-center mt-4">
          <FloatLabel class="w-full">
            <Dropdown
              v-model="selectedTraining2"
              inputId="dd-tr2"
              :options="modules"
              optionLabel="short"
              class="w-full"
              filter
            />
            <label for="dd-tr2">{{
              dictionary.comparator_dropdown_label
            }}</label>
          </FloatLabel>
        </div>
      </div>
    </div>
    <div v-else class="pt-4 w-full flex flex-col">
      <VueFlow
        :nodes="nodes"
        :edges="edges"
        ref="vueFlowRef"
        :default-viewport="{ zoom: 1.2 }"
        :min-zoom="1.2"
        :max-zoom="1.2"
      >
      </VueFlow>
    </div>
    <div class="w-full flex justify-left p-4">
      <Button
        v-if="showDropdown"
        :label="dictionary.comparator_btn_compare"
        icon="pi pi-arrow-right-arrow-left"
        @click="handleCompareModules()"
      ></Button>
      <Button
        v-else
        :label="dictionary.comparator_btn_clear"
        icon="pi pi-refresh"
        @click="clearTrainings"
      ></Button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { VueFlow, useVueFlow } from "@vue-flow/core";
import Dropdown from "primevue/dropdown";
import FloatLabel from "primevue/floatlabel";
import { dictionary } from "../../dictionary.js";
import Button from "primevue/button";

const findCommonAndDifferences = (value1, value2) => {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    const common = value1.filter((value) => value2.includes(value));
    const differences = value1
      .concat(value2)
      .filter((value) => !common.includes(value));
    return { common, differences };
  }

  return {
    common: value1 === value2 ? [value1] : [],
    differences: value1 !== value2 ? [value1, value2] : [],
  };
};

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    Dropdown,
    FloatLabel,
    Button,
    VueFlow,
  },
  setup(props) {
    const { onInit, onConnect, addEdges } = useVueFlow();
    const modules = ref(
      [...props.data].sort((a, b) => a.short.localeCompare(b.short))
    );
    const selectedTraining1 = ref(null);
    const selectedTraining2 = ref(null);
    const commonElements = ref({});
    const differences = ref({});
    const showDropdown = ref(true);
    const nodes = ref([]);
    const edges = ref([]);

    const clearTrainings = () => {
      selectedTraining1.value = null;
      selectedTraining2.value = null;
      commonElements.value = {};
      differences.value = {};
      showDropdown.value = true;
    };

    onInit((vueFlowInstance) => {
      vueFlowInstance.fitView();
    });

    onConnect((connection) => {
      addEdges(connection);
    });

    const updatePos = () => {
      nodes.value = nodes.value.map((node) => {
        return {
          ...node,
          position: {
            x: Math.random() * 400,
            y: Math.random() * 400,
          },
        };
      });
    };

    const handleCompareModules = () => {
      showDropdown.value = false;
      const spec1 = selectedTraining1.value.specification[0];
      const spec2 = selectedTraining2.value.specification[0];

      const duration = findCommonAndDifferences(
        selectedTraining1.value.duration,
        selectedTraining2.value.duration
      );
      const type = findCommonAndDifferences(
        selectedTraining1.value.type,
        selectedTraining2.value.type
      );
      const element = findCommonAndDifferences(
        selectedTraining1.value.element,
        selectedTraining2.value.element
      );
      const department = findCommonAndDifferences(
        selectedTraining1.value.department,
        selectedTraining2.value.department
      );
      const target = findCommonAndDifferences(
        selectedTraining1.value.target,
        selectedTraining2.value.target
      );
      const employee = findCommonAndDifferences(
        selectedTraining1.value.employee,
        selectedTraining2.value.employee
      );
      const topics = findCommonAndDifferences(spec1.topic, spec2.topic);
      const stopic = findCommonAndDifferences(spec1.stopic, spec2.stopic);
      const skill_level = findCommonAndDifferences(
        spec1.skill_level,
        spec2.skill_level
      );
      const knowledge = findCommonAndDifferences(
        spec1.knowledge,
        spec2.knowledge
      );
      const objective = findCommonAndDifferences(
        [spec1.objective],
        [spec2.objective]
      );
      console.log({
        duration,
        type,
        element,
        department,
        target,
        employee,
        topics,
        stopic,
        skill_level,
        knowledge,
        objective,
      });
      return {
        duration,
        type,
        element,
        department,
        target,
        employee,
        topics,
        stopic,
        skill_level,
        knowledge,
        objective,
      };
    };
    const sourceExists = (array, source) => {
      return array.value.some((node) => node.id === source);
    };

    const targetExists = (array, target) => {
      return array.value.some((node) => node.id === target);
    };

    const createEdge = (array, id, source, target, edgeClass) => {
      if (sourceExists(array, source) && targetExists(array, target)) {
        return {
          id: id,
          source: source,
          target: target,
          class: edgeClass,
        };
      }
      return null;
    };

    watch(
      () => [
        selectedTraining1.value,
        selectedTraining2.value,
        showDropdown.value,
      ],
      ([newTraining1, newTraining2]) => {
        if (newTraining1 && newTraining2 && !showDropdown.value) {
          const m1 = newTraining1.short;
          const m2 = newTraining2.short;
          const comparedModules = handleCompareModules();
          const dept = comparedModules.department;
          const duration = comparedModules.duration;
          // const elem = comparedModules.element;
          // const empl = comparedModules.employee;
          const knowledge = comparedModules.knowledge;
          // const objective = comparedModules.objective;
          // const skillLvl = comparedModules.skill_level;
          // const stopic = comparedModules.stopic;
          // const target = comparedModules.target;
          // const topics = comparedModules.topics;
          // const type = comparedModules.type;

          nodes.value = [
            {
              id: m1.toLowerCase() + "_1",
              class: "vue-flow-comp-modules",
              label: m1,
              position: { x: 50, y: -600 },
            },
            {
              id: m1.toLowerCase() + "_2",
              class: "vue-flow-comp-modules",
              label: m2,
              position: { x: 50, y: -550 },
            },
            {
              id: m1.toLowerCase() + "_3",
              class: "vue-flow-comp-common",
              label: dictionary.comparator_common,
              position: { x: -160, y: -580 },
            },
            {
              id: m1.toLowerCase() + "_4",
              class: "vue-flow-comp-differences",
              label: dictionary.comparator_differences,
              position: { x: 250, y: -580 },
            },
          ];

          if (knowledge.differences.length > 0) {
            nodes.value.push({
              id: m1.toLowerCase() + "_5",
              class: "vue-flow-diff-knowledge",
              label: knowledge.differences
                .map((label) => {
                  return `<span class = "node-diff-knowledge">${label}</span>`;
                })
                .join(""),
              position: { x: 400, y: -850 },
            });
          } else {
            nodes.value.push({
              id: m1.toLowerCase() + "_6",
              class: "vue-flow-common-knowledge",
              label: knowledge.common
                .map((label) => {
                  return `<span class = "node-common-knowledge">${label}</span>`;
                })
                .join(""),
              position: { x: -350, y: -850 },
            });
          }

          if (dept.differences.length > 0) {
            nodes.value.push({
              id: m1.toLowerCase() + "_7",
              class: "vue-flow-diff-dept",
              label: dept.differences
                .map((label) => {
                  return `<span class = "node-diff-dept">${
                    dictionary.filter_dialog_department + label
                  }</span>`;
                })
                .join(""),
              position: { x: 475, y: -550 },
            });
          } else {
            nodes.value.push({
              id: m1.toLowerCase() + "_8",
              class: "vue-flow-common-dept",
              label: dept.common
                .map((label) => {
                  return `<span class = "node-common-dept">${
                    dictionary.filter_dialog_department + label
                  }</span>`;
                })
                .join(""),
              position: { x: -325, y: -550 },
            });
          }
          if (duration.differences.length > 0) {
            nodes.value.push({
              id: m1.toLowerCase() + "_9",
              class: "vue-flow-diff-duration",
              label: duration.differences
                .map((label) => {
                  return `<span class = "node-diff-duration">${
                    dictionary.filter_dialog_duration + label
                  }min</span>`;
                })
                .join(""),
              position: { x: 200, y: -650 },
            });
          } else {
            nodes.value.push({
              id: m1.toLowerCase() + "_10",
              class: "vue-flow-common-duration",
              label: duration.common
                .map((label) => {
                  return `<span class = "node-common-duration">${
                    dictionary.filter_dialog_duration + label
                  }min</span>`;
                })
                .join(""),
              position: { x: -175, y: -650 },
            });
          }

          edges.value = [
            createEdge(
              nodes,
              m1.toLowerCase() + "_1_" + m2.toLowerCase() + "_2",
              m1.toLowerCase() + "_1",
              m1.toLowerCase() + "_2",
              "comp-mod vue-flow-module-name-edge"
            ),
          ];
        }
      },
      { immediate: true }
    );

    return {
      modules,
      dictionary,
      selectedTraining1,
      selectedTraining2,
      commonElements,
      differences,
      showDropdown,
      nodes,
      edges,
      handleCompareModules,
      updatePos,
      clearTrainings,
    };
  },
};
</script>
<style scoped>
.wrapper {
  flex-wrap: wrap;
}
.wrapper span {
  padding: 1rem 1.5rem;
  border: 1px solid;
  flex: 1;
}
.title {
  background-color: #2e75b6;
}
.trainings {
  color: #fbbf24;
  font-size: xx-large;
  font-weight: bold;
}

button {
  width: 25%;
  padding: 1rem;
  transition: ease-in-out;
  transition-duration: 0.3s;
  color: #374151;
  background-color: rgba(254, 196, 30, 1);
}

button:hover {
  background-color: transparent;
  color: rgba(254, 196, 30, 1);
}
</style>
